import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';

import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Rating from '@mui/material/Rating';
import InfoIcon from '@mui/icons-material/Info';
import DrawerHeader from 'app/components/shared/DrawerHeader';

import { callButton } from 'app/helpers/listingHelpers';
import { toCurrency } from 'app/helpers/currencyHelpers';

const ListingDetailDrawer = ({
  open,
  onClose,
  listing,
  onFeedbackClick,
  onImageClick,
}) => {
  if (!listing) { return null; }

  const advisorDescriptionStyles = {
    'font:not(font font)': {
      fontSize: 'calc(100% / 1.02)',
    },
    'font[size="1"]:not(font font)': {
      fontSize: 'calc(0.75em / 1.02) !important',
    },
    'font[size="2"]:not(font font)': {
      fontSize: 'calc(0.875em / 1.02) !important',
    },
    'font[size="3"]:not(font font)': {
      fontSize: 'calc(1em / 1.02) !important',
    },
    'font[size="4"]:not(font font)': {
      fontSize: 'calc(1.125em / 1.02) !important',
    },
    'font[size="5"]:not(font font)': {
      fontSize: 'calc(1.25em / 1.02) !important',
    },
    'font[size="6"]:not(font font)': {
      fontSize: 'calc(1.5em / 1.02) !important',
    },
    'font[size="7"]:not(font font), font[size="8"]:not(font font)': {
      fontSize: 'calc(2em / 1.02) !important',
    },
    'span[style*="font-size"], div[style*="font-size"], p[style*="font-size"], h1, h2, h3, h4, h5, h6': {
      fontSize: 'calc(100% / 1.02) !important',
    },
    'table, table thead, table th, table tbody, table td, table tr, div, center': {
      width: 'auto !important',
      height: 'auto !important',
      maxWidth: 'calc(100vw - 20px)',
    },
    img: {
      maxWidth: '100%',
      height: 'auto',
    },
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
    >
      <Box
        sx={{
          width: { xs: '100vw' },
          maxWidth: { xs: '100vw', sm: '600px' },
        }}
      >
        <DrawerHeader
          closeDrawer={onClose}
          headerCopy="Listing Details"
        />
        <Box
          sx={{
            backgroundColor: '#82468b',
            padding: '20px',
            color: 'white',
            a: { color: 'white' },
          }}
          dangerouslySetInnerHTML={{ __html: listing.breadcrumbs }}
        />
        <Box sx={{ textAlign: 'center', padding: '10px' }}>
          <a onClick={onImageClick} role="link" tabIndex={0}>
            <img
              style={{ maxHeight: '250px' }}
              src={listing.photoUrl}
              alt="Listing"
            />
          </a>
        </Box>
        <Box sx={{ padding: 2 }}>
          <Box mb={2}>
            <Link to={`/${listing.login}`}>
              {listing.login}
            </Link>
            <Typography variant="body1">
              {listing.title}
            </Typography>
          </Box>
          <Grid container>
            <Grid item xs={6}>
              <a onClick={onFeedbackClick} role="link" tabIndex={0}>
                <Rating
                  value={listing.rating}
                  precision={0.5}
                  readOnly
                  sx={{ verticalAlign: 'middle' }}
                />
                <Typography
                  display="inline"
                  sx={{ color: 'grey', verticalAlign: 'middle' }}
                  pl={1}
                >
                  (
                  {listing.totalRatings}
                  )
                </Typography>
              </a>
              <Stack direction="row">
                <IconButton>
                  <InfoIcon />
                </IconButton>
                <Typography>
                  call me direct
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              {callButton(listing)}
              <Typography>
                {`${toCurrency(listing.pricePerMinute)} per min.`}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Box
                sx={advisorDescriptionStyles}
                dangerouslySetInnerHTML={{ __html: listing.description }}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Drawer>
  );
};

ListingDetailDrawer.defaultProps = {
  listing: undefined,
};

ListingDetailDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  listing: PropTypes.object,
  onFeedbackClick: PropTypes.func.isRequired,
  onImageClick: PropTypes.func.isRequired,
};

export default ListingDetailDrawer;
