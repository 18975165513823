import React from 'react';
import { PropTypes } from 'prop-types';
import {
  Box,
  Button,
  Stack,
  Typography,
} from '@mui/material';

import PackagesIconNF from 'app/components/customSvgs/PackagesIconNF';
import AuthenticatedLink from './shared/AuthenticatedLink';
import { TRIBUTE_ACTIONS } from 'app/constants/tributeActions';

const RecurringTributeDetails = ({
  currentUser,
  currentFilter,
  monetaryTributeAction,
  tributeData,
}) => {
  return (
    <Box mt={1}>
      <Typography variant="h4" pl={1}>Recurring Tributes</Typography>
      {(currentFilter === 'active') && (
        <Box mt={1} pl={1} pr={1} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', flexDirection: 'row' }}>
          <Box>
            <AuthenticatedLink
              role="link"
              tabIndex={0}
              title="Edit Recurring Tribute"
              currentUser={currentUser}
              onClick={() => monetaryTributeAction(TRIBUTE_ACTIONS.EDIT_MONETARY_TRIBUTE)}
            >
              {tributeData.rate}
              {' '}
              per month
            </AuthenticatedLink>
          </Box>
          {tributeData.status === 'active' && (
            <Box>
              Renews
              {' '}
              {tributeData.renewalDate}
            </Box>
          )}
          {tributeData.status === 'pending_cancel' && (
            <Box>
              Expires
              {' '}
              {tributeData.renewalDate}
            </Box>
          )}
        </Box>
      )}
      {(currentFilter === 'expired') && (
        <Box mt={1} pl={1} pr={1} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', flexDirection: 'row' }}>
          <Box>
            Expired
          </Box>
          <Box>
            {tributeData.renewalDate}
          </Box>
        </Box>
      )}
      <Typography pl={1} mt={2}>There are no perks associated with this tribute.</Typography>
      {(!tributeData.renewalDate || currentFilter === 'expired') && (
        <Box mt={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '5px' }}>
          <Button
            variant="contained"
            color="secondary"
            size="medium"
            onClick={() => monetaryTributeAction(TRIBUTE_ACTIONS.NEW_MONETARY_TRIBUTE, tributeData.rate)}
            style={{ maxWidth: '230px' }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <PackagesIconNF style={{ marginRight: '5px' }} />
              <Typography sx={{ fontSize: '1.125em' }}>
                {tributeData.rate}
              </Typography>
              <Typography sx={{ fontWeight: '300', fontSize: '0.938em', pt: 0.4 }}>
                per month
              </Typography>
            </Stack>
          </Button>
        </Box>
      )}
    </Box>
  );
};

RecurringTributeDetails.propTypes = {
  currentFilter: PropTypes.string.isRequired,
  currentUser: PropTypes.object.isRequired,
  monetaryTributeAction: PropTypes.func.isRequired,
  tributeData: PropTypes.object.isRequired,
};

export default RecurringTributeDetails;
