import React from 'react';
import PropTypes from 'prop-types';

import CallListingShape from 'app/shapes/CallListingShape';

import {
  Button,
  Grid,
  Stack,
} from '@mui/material';

import CallButton from 'app/components/shared/CallButton';
import ListenButton from 'app/components/shared/ListenButton';
import SendMailButton from 'app/components/shared/SendMailButton';
import SendTextButton from 'app/components/shared/SendTextButton';
import { navigateTo } from '../../util/navigation';

const CallNowActionButton = ({
  listing,
  triggerCall,
  canCall,
}) => {
  const mailUrl = `/messages/compose?message%5Brecipients_list%5D=${listing?.login}`;
  const chatUrl = `/chat/${listing?.advisor_slug}`;

  const isBusy = listing.advisor_busy;
  const isAvailable = listing.advisor_availability === 1;
  const canText = listing.can_text;

  let button;
  if (listing?.recorded) {
    button = (
      <ListenButton
        disabled={!canCall}
        onClick={canCall ? triggerCall : () => {}}
      />
    );
  } else if (isBusy) {
    button = (
      <Stack direction="row" spacing={2} alignItems="center">
        <a href={`${listing.listing_category_path}`}>
          <Button
            variant="contained"
            size="small"
          >
            Find a Flirt
          </Button>
        </a>
        <SendMailButton
          status="busy"
          onClick={() => navigateTo(mailUrl)}
        />
      </Stack>
    );
  } else if (isAvailable) {
    button = (
      <CallButton
        disabled={!canCall}
        onClick={canCall ? triggerCall : () => {}}
      />
    );
  } else if (canText) { // implies !busy && !isAvailable
    button = (
      <Stack direction="row" spacing={2} alignItems="center">
        <a href={`${listing.listing_category_path}`}>
          <Button
            variant="contained"
            size="small"
          >
            Find a Flirt
          </Button>
        </a>
        <SendTextButton
          onClick={() => navigateTo(chatUrl)}
        />
      </Stack>
    );
  } else { // implies !busy && !isAvailable && !canText
    button = (
      <Stack direction="row" spacing={2} alignItems="center">
        <a href={`${listing.listing_category_path}`}>
          <Button
            variant="contained"
            size="small"
          >
            Find a Flirt
          </Button>
        </a>
        <SendMailButton
          status="away"
          onClick={() => navigateTo(mailUrl)}
        />
      </Stack>
    );
  }

  return (
    <Grid
      item
      xs={12}
      mt={2}
      mb={1}
    >
      <Stack
        direction="row"
        mt={3}
        justifyContent="center"
        sx={{ textAlign: 'center' }}
      >
        { listing && button }
      </Stack>
    </Grid>
  );
};

CallNowActionButton.defaultProps = {
  listing: null,
};

CallNowActionButton.propTypes = {
  listing: CallListingShape,
  triggerCall: PropTypes.func.isRequired,
  canCall: PropTypes.bool.isRequired,
};

export default CallNowActionButton;
