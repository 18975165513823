import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Grid,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
  Stack,
} from '@mui/material';

import ListingSearchResults from 'app/components/listing_search/ListingSearchResults';
import ListingSort from 'app/components/listing_search/ListingSort';

const TabPanel = ({
  children,
  typeId,
  index,
}) => {
  return (
    <div
      role="tabpanel"
      hidden={typeId !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {typeId === index && (
        <Box sx={{ pt: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

TabPanel.defaultProps = {
  children: null,
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  typeId: PropTypes.number.isRequired,
};

const ListingTypeTabs = ({
  TABS,
  typeId,
  setTypeId,
  isLoading,
  searchResults,
  handleSortChange,
  // sort,
  page,
  setPage,
  handlePageChange,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const getTotalListings = () => {
    switch (typeId) {
    case 1:
      return searchResults?.total_listings || 0;
    case 2:
      return searchResults?.total_r_listings || 0;
    case 3:
      return searchResults?.total_chats || 0;
    default:
      return 0;
    }
  };

  // Results generated by a search do not include the total number of pages so
  // we must calculate it ourselves.
  const lastPage = Math.ceil(getTotalListings() / 12);

  const handleTabChange = (event, newValue) => {
    setTypeId(newValue);
    setPage(1);
  };

  return (
    <>
      <Grid
        container
        sx={{
          px: { xs: 0, sm: 1 },
          mb: -3, // Reduce white space between Tabs and ListingSearchResults
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Grid item xs={12}>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="space-between"
            alignItems="center"
          >
            {/* Tabs Section */}
            <Box sx={{ flex: 1 }}>
              <Tabs value={typeId} onChange={handleTabChange} variant="fullWidth">
                <Tab
                  label={isMobile ? 'Phone Sex' : 'Live Phone Sex'}
                  value={1}
                  sx={{
                    whiteSpace: 'nowrap',
                    maxWidth: '150px',
                  }}
                />
                <Tab
                  label={isMobile ? 'Recorded' : 'Recorded Phone Sex'}
                  value={2}
                  sx={{
                    maxWidth: '200px',
                  }}
                />
                <Tab
                  label="Chat"
                  value={3}
                  sx={{
                    maxWidth: '100px',
                  }}
                />
              </Tabs>
            </Box>

            {/* Sort Section or Placeholder */}
            <Box
              sx={{
                width: '150px',
                height: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                paddingRight: '10px',
              }}
            >
              {typeId !== 3 ? (
                <ListingSort handleSortChange={handleSortChange} />
              ) : (
                <span />
              )}
            </Box>
          </Stack>
        </Grid>
      </Grid>

      <TabPanel typeId={typeId} index={TABS.live}>
        <ListingSearchResults
          isLoading={isLoading}
          cardType="live"
          records={searchResults?.listings}
          page={page}
          lastPage={lastPage}
          handlePageChange={handlePageChange}
        />
      </TabPanel>
      <TabPanel typeId={typeId} index={TABS.recorded}>
        <ListingSearchResults
          isLoading={isLoading}
          cardType="live"
          records={searchResults?.r_listings}
          page={page}
          lastPage={lastPage}
          handlePageChange={handlePageChange}
        />
      </TabPanel>
      <TabPanel typeId={typeId} index={TABS.chat}>
        <ListingSearchResults
          isLoading={isLoading}
          cardType="chat"
          records={searchResults?.chats}
          page={page}
          lastPage={lastPage}
          handlePageChange={handlePageChange}
        />
      </TabPanel>
    </>
  );
};

ListingTypeTabs.defaultProps = {
  searchResults: {},
};

ListingTypeTabs.propTypes = {
  TABS: PropTypes.object.isRequired,
  typeId: PropTypes.number.isRequired,
  setTypeId: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  searchResults: PropTypes.object,
  handleSortChange: PropTypes.func.isRequired,
  // sort: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  handlePageChange: PropTypes.func.isRequired,
};

export default ListingTypeTabs;
