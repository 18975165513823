import React from 'react';
import { PropTypes } from 'prop-types';
import { useParams } from 'react-router';
import { useMatch } from 'react-router-dom';

import FlirtCard from 'app/components/FlirtCard';
import FlirtCardPlaceholder from 'app/components/FlirtCardPlaceholder';
import TributeCardContainer from 'app/containers/TributeCardContainer';
import TributeCardPlaceholder from 'app/components/TributeCardPlaceholder';
import BackArrow from 'app/components/shared/BackArrow';

import SearchControlsContainer from 'app/containers/SearchControlsContainer';
import SearchContainer from 'app/containers/SearchContainer';
import { useGetAppConfigQuery } from 'app/api/mainApi';

import { featureFlag } from 'app/util/featureFlag';

const emptyTabText = {
  active: 'No active subscriptions.',
  needs_attention: 'Yay! There is nothing here for you to review.',
  expired: 'Hooray! You don’t have anything expiring soon.',
};

const MySubscriptions = ({
  subscriptions,
  tributes,
  follows,
  isLoading,
  isTributesLoading,
  filterButtonClick,
  currentFilter }) => {
  let content;
  const { login: advisorLogin } = useParams();
  const inStorefront = useMatch(`/${advisorLogin}/feed/subscriptions`);
  const packagesEnabled = featureFlag.enabled('RECURRING_PACKAGE_TRIBUTES_19537');
  const CardComponent = packagesEnabled ? TributeCardContainer : FlirtCard;
  const PlaceholderComponent = packagesEnabled ? TributeCardPlaceholder : FlirtCardPlaceholder;

  const appConfig = useGetAppConfigQuery();
  const currentUser = appConfig?.data?.current_user;

  if (isLoading || isTributesLoading) {
    content = (
      <>
        <PlaceholderComponent />
        <PlaceholderComponent />
        <PlaceholderComponent />
      </>
    );
  } else if (!packagesEnabled && subscriptions?.length === 0) {
    content = <div className="margin10">{emptyTabText[currentFilter]}</div>;
  } else if (packagesEnabled) {
    const allTributes = tributes.packageTributes.concat(tributes.monetaryTributes);
    content = allTributes.map((tributeData) => {
      let follow;
      if (follows?.length > 0) {
        follow = follows.find((f) => {
          return f.creator_account.main_user_id === tributeData.advisor_id;
        });
      }

      return (
        <CardComponent
          key={tributeData.id}
          tributeData={tributeData}
          follow={follow}
          currentFilter={currentFilter}
        />
      );
    });
  } else {
    content = subscriptions.map((subscriptionData) => {
      let follow;
      if (follows?.length > 0) {
        follow = follows.find((f) => {
          return f.creator_account.main_user_id === subscriptionData.subscription.advisor_id;
        });
      }

      return (
        <CardComponent
          key={subscriptionData.subscription.id}
          subscriptionData={subscriptionData}
          currentUser={currentUser}
          follow={follow}
        />
      );
    });
  }

  return (
    <>
      {!inStorefront && (
        <SearchContainer>
          <div className="container-hdr-sticky2">
            <div className="row middle-xs start-xs nowrap-xs">
              <div className="col-xs-10">
                <BackArrow />
                <h2>Subscriptions</h2>
              </div>
            </div>
          </div>
          <SearchControlsContainer />
        </SearchContainer>
      )}

      <div className="container-summary-tags" style={{ borderBottom: '1px solid #ddd', marginTop: '10px' }}>
        <div className="row middle-xs start-xs">
          <span
            className={`${currentFilter === 'active' ? 'active' : ''}`}
            role="link"
            tabIndex={0}
            onClick={() => filterButtonClick('active')}
          >
            Active
          </span>
          <span
            className={`${currentFilter === 'needs_attention' ? 'active' : ''}`}
            role="link"
            tabIndex={0}
            onClick={() => filterButtonClick('needs_attention')}
          >
            Needs Attention
          </span>
          <span
            className={`${currentFilter === 'expired' ? 'active' : ''}`}
            role="link"
            tabIndex={0}
            onClick={() => filterButtonClick('expired')}
          >
            Expired
          </span>
        </div>
      </div>
      <div className="container-fluid padding10 paddingtop0">
        <div className="row">
          <div className="col-xs-12 center-xs">
            <div className="container-fluid padding0">
              <div className="row start-xs">
                {content}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

MySubscriptions.defaultProps = {
  subscriptions: [],
  tributes: {},
  follows: [],
};

MySubscriptions.propTypes = {
  subscriptions: PropTypes.array,
  tributes: PropTypes.object,
  follows: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  isTributesLoading: PropTypes.bool.isRequired,
  filterButtonClick: PropTypes.func.isRequired,
  currentFilter: PropTypes.string.isRequired,
};

export default MySubscriptions;
