/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import { Autocomplete, Checkbox, FormControl, TextField } from '@mui/material';

// This is a versatile reusable dropdown-
//   it can have checkboxes or not , based on the single prop
//   it can allow autocomplete or not, based on the allowTyping prop
export const AutoCompleteMultiCheckbox = ({ label, options, value, setValue, allowTyping, single, showCount, counts }) => {
  const multiple = !single;
  const onChange = (_event, newValue) => {
    setValue(newValue);
  };

  return (
    <FormControl fullWidth variant="outlined" margin="normal">
      <Autocomplete
        multiple={multiple}
        disableCloseOnSelect
        options={options}
        value={value || []}
        onChange={onChange}
        isOptionEqualToValue={(option, value) => value === option}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            autoComplete="off"
            autoCapitalize="none"
            autoCorrect="off"
            spellCheck="false"
            inputProps={{
              ...params.inputProps,
              readOnly: !allowTyping,
              inputMode: 'search',
              autoComplete: 'off',
              autoCapitalize: 'none',
              autoCorrect: 'off',
              spellCheck: 'false',
            }}
          />
        )}
        renderOption={(props, option, { selected }) => {
          if (showCount && counts[option] === 0) {
            return null;
          }

          const { key, ...optionProps } = props;
          return (
            <li key={key} {...optionProps}>
              { multiple &&
                (
                  <Checkbox
                    size="small"
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                )}
              {option}
              { showCount && <span>&nbsp;({counts[option]})</span> }
            </li>
          );
        }}
      />
    </FormControl>
  );
};

AutoCompleteMultiCheckbox.defaultProps = {
  options: [], // briefly, while loading
  allowTyping: false,
  counts: {},
  showCount: false,
};

AutoCompleteMultiCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  setValue: PropTypes.func.isRequired,
  allowTyping: PropTypes.bool,
  showCount: PropTypes.bool,
  counts: PropTypes.object,
};

export default AutoCompleteMultiCheckbox;
