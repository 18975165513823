import { useContext } from 'react';

import useRecurringTributeManagement from 'app/hooks/useRecurringTributeManagement';
import DialogsContext from 'app/contexts/DialogsContext';
import TributeWorkflowContainer from 'app/containers/modals/tributes/TributeWorkflowContainer';
import { TRIBUTE_WORKFLOW_STEPS } from 'app/constants/tributeWorkflowSteps';
import { TRIBUTE_ACTIONS } from 'app/constants/tributeActions';

const useTributeActions = (advisor, advisorName) => {
  const { openDialog } = useContext(DialogsContext);
  const {
    closeDialogAndResetTributeSlice,
    dispatch,
    setAmount,
    setCurrentStep,
    setPackageId,
    setPackageRate,
    setRecurring,
  } = useRecurringTributeManagement({ advisor });

  const monetaryTributeAction = (action, amount) => {
    closeDialogAndResetTributeSlice();
    openDialog({
      component: TributeWorkflowContainer,
      props: { advisorLogin: advisorName },
    });

    switch (action) {
    case TRIBUTE_ACTIONS.EDIT_MONETARY_TRIBUTE:
      return dispatch(setCurrentStep({
        currentStep: TRIBUTE_WORKFLOW_STEPS.EDIT_MONETARY_TRIBUTE,
      }));
    case TRIBUTE_ACTIONS.NEW_MONETARY_TRIBUTE:
      dispatch(setRecurring({ recurring: true }));
      setAmount(amount.replace('$', ''));
      return dispatch(setCurrentStep({
        currentStep: TRIBUTE_WORKFLOW_STEPS.CONFIRM_CREATE_MONETARY_TRIBUTE,
      }));
    default:
      return null;
    }
  };

  const packageTributeAction = (action, packageId, packageRate) => {
    closeDialogAndResetTributeSlice();
    openDialog({
      component: TributeWorkflowContainer,
      props: { advisorLogin: advisorName, packageId },
    });

    switch (action) {
    case TRIBUTE_ACTIONS.EDIT_PACKAGE_TRIBUTE:
      dispatch(setPackageId({ packageId }));
      dispatch(setPackageRate({ packageRate }));
      return dispatch(setCurrentStep({
        currentStep: TRIBUTE_WORKFLOW_STEPS.EDIT_PACKAGE_TRIBUTE,
      }));
    default:
      return null;
    }
  };

  return {
    monetaryTributeAction,
    packageTributeAction,
  };
};

export default useTributeActions;
