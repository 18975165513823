import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router';

import { useGetAppConfigQuery, useGetAdvisorQuery } from 'app/api/mainApi';
import AppBodyWithSidebar from 'app/components/layout/AppBodyWithSidebar';
import ProfilePageHeader from 'app/components/ProfilePageHeader';
import ProfileTabsWithContent from 'app/components/ProfileTabsWithContent';
import ProfileContext from 'app/contexts/ProfileContext';

const ProfilePageContainer = (props) => {
  const [stickyNavVisible, setStickyNavVisible] = useState(false);
  const { login: advisorLogin } = useParams();

  const {
    data: advisor,
    error: advisorError,
    isLoading: advisorLoading,
  } = useGetAdvisorQuery({ login: advisorLogin });

  useEffect(() => {
    if (window.location.hash === '#chat' && advisor?.slug) {
      window.location.href = `/chat/${advisor.slug}`;
    }
  }, [advisor?.slug]);

  useEffect(() => {
    if (window.location.hash === '#goodies') {
      window.location.href = `/${advisorLogin}/goodies`;
    }
  })

  const appConfig = useGetAppConfigQuery();
  const currentUser = appConfig?.data?.current_user;
  const viewingSelf = (currentUser?.id === advisor?.id);

  // we don't need to avoid an expensive calculation- instead we're just trying to avoid excessive
  // re-renders by turning this string that is passed in to a child component into a persistent object
  const contextProps = useMemo(
    () => ({
      ...props,
      returnUrlTemplate: `/${advisorLogin}/gb/:goodyBagId?action=buy`,
    }),
    [props, advisorLogin],
  );

  useEffect(() => {
    const onScroll = (e) => {
      setStickyNavVisible(e.target.scrollTop > 180);
    };

    const bodyContent = document.getElementById('body-content');
    if (bodyContent) { bodyContent.addEventListener('scroll', onScroll); }

    return () => {
      bodyContent.removeEventListener('scroll', onScroll);
    };
  }, []);

  useEffect(() => {
    if (advisorError?.status === 404) {
      window.location.href = '/landing/missing/profile';
    }
  }, [advisorError]);

  return (
    <AppBodyWithSidebar>
      <ProfileContext.Provider value={contextProps}>
        <ProfilePageHeader
          advisor={advisor}
          currentUser={currentUser}
          stickyNavVisible={stickyNavVisible}
          viewingSelf={viewingSelf}
        />
        <ProfileTabsWithContent
          stickyNavVisible={stickyNavVisible}
          advisor={advisor}
          advisorLoading={advisorLoading}
          viewingSelf={viewingSelf}
          currentUser={currentUser}
        />
      </ProfileContext.Provider>
    </AppBodyWithSidebar>
  );
};

export default ProfilePageContainer;
