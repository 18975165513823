import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Lightbox from 'lightbox-react';
import Box from '@mui/material/Box';

import ListingDetailDrawer from 'app/components/ListingDetailDrawer';
import { useGetListingQuery } from 'app/api/mainApi';
import { callButton } from 'app/helpers/listingHelpers';

const ListingDetailDrawerContainer = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const listingId = searchParams.get('listing_id');
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);

  const {
    data: listing,
    isSuccess,
  } = useGetListingQuery({ id: listingId }, { skip: !listingId });

  const onDrawerClose = () => {
    navigate(window.location.pathname);
  };

  const onFeedbackClick = () => {
    navigate(
      `${window.location.pathname}?listing_id=${listingId}&feedback_listing_id=${listingId}`,
    );
  };

  const onImageClick = () => {
    setLightboxIsOpen(true);
  };

  const onLightboxClose = () => {
    setLightboxIsOpen(false);
  };

  useEffect(() => {
    if (isSuccess) { setOpen(true); }
  }, [listing]);

  useEffect(() => {
    if (!listingId) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [listingId]);

  const myCallButton = (listing) => {
    return (
      <Box
        sx={{
          position: 'fixed',
          bottom: '20px',
          right: 'calc(50% - 70px)',
        }}
      >
        {callButton(listing)}
      </Box>
    );
  };

  return (
    <Box>
      <ListingDetailDrawer
        open={open}
        onClose={onDrawerClose}
        listing={listing}
        onFeedbackClick={onFeedbackClick}
        onImageClick={onImageClick}
      />
      {lightboxIsOpen && (
        <Box>
          <Lightbox
            reactModalStyle={{ overlay: { zIndex: 9000 } }}
            mainSrc={listing.photo_url}
            onCloseRequest={onLightboxClose}
            imageTitle={listing.title}
            toolbarButtons={[myCallButton(listing)]}
          />
        </Box>
      )}
    </Box>
  );
};

export default ListingDetailDrawerContainer;
