import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate, useMatch } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Lightbox from 'lightbox-react';

import { Box, Grid, Link, Typography } from '@mui/material';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';

import { useLazyGetGoodyBagItemsQuery, useLazyGetGoodyBagReviewsQuery } from 'app/api/assetApi';
import GoodyBagShape from 'app/shapes/GoodyBagShape';
import useFlirtMedia from 'app/hooks/useFlirtMedia';
import StyledCard from 'app/components/shared/StyledCard';
import BlurBox from 'app/components/shared/BlurBox';
import RightColumn from 'app/components/shared/RightColumn';
import NameTypography from 'app/components/shared/NameTypography';
import DescBox from 'app/components/shared/DescBox';
import RatingContainer from 'app/components/shared/RatingContainer';
import { toCurrency } from 'app/helpers/currencyHelpers';
import useGoodyBagActions from 'app/hooks/useGoodyBagActions';
import AuthenticatedLink from 'app/components/shared/AuthenticatedLink';
import GoodyBagDetailsDrawer from 'app/components/modals/GoodyBagDetailsDrawer';
import GoodyBagReviewsList from 'app/components/modals/GoodyBagReviewsList';
import ProfileContext from 'app/contexts/ProfileContext';
import GoodySearchContext from 'app/contexts/GoodySearchContext';
import SanitizedHTML from 'app/helpers/SanitizedHTML';
import { goodyBagItemToMediaParams } from 'app/helpers/flirtMediaHelpers';
import GoodyBagCallToAction from 'app/components/media/GoodyBagCallToAction';
import GoodyBagActionMenu from 'app/components/shared/GoodyBagActionMenu';

const GoodyBagCard = ({
  goodyBag,
  viewingSelf,
  availableBalance,
  currentUser,
  advisorLoading,
  selected,
  advisor,
  viewingSingleAdvisor,
  actions,
  titleIsLink,
}) => {
  const navigate = useNavigate();
  const encodedAdvisorLogin = encodeURIComponent(advisor?.login);
  const showContext = useMatch(`/${encodedAdvisorLogin}/*`);
  const [triggerGoodyBagItemsQuery, goodyBagItemsResult] = useLazyGetGoodyBagItemsQuery({ goodyBagId: goodyBag.id });
  const goodyBagItems = goodyBagItemsResult?.data || [];
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const action = searchParams.get('action'); // only used so we can know whether we need to remove it from the URL
  const selectedGoodyBagId = searchParams.get('goodyBagId') || params.goodyBagId;
  const profileContext = useContext(ProfileContext);
  const goodySearchContext = useContext(GoodySearchContext);
  const returnUrlTemplate = profileContext?.returnUrlTemplate || goodySearchContext?.returnUrlTemplate;
  const pinIconClass = viewingSelf ? 'active' : 'disabled';

  const [triggerGoodyBagReviewsQuery, goodyBagReviewsResult] = useLazyGetGoodyBagReviewsQuery({ goodyBagId: goodyBag.id });
  const goodyBagReviews = goodyBagReviewsResult?.data || [];
  const notPreviewableItemCount = goodyBagItems.filter(item => !item.previewable).length;

  // Lightbox Management
  const mediaItems = goodyBagItems
    .filter(item => item.previewable)
    .map((goodyBagItem) => goodyBagItemToMediaParams(goodyBagItem));

  // if any items were hidden, insert a "plus some hot ones!" call to action at the end
  if (notPreviewableItemCount > 0) {
    mediaItems.push({ type: 'thumb', thumb: <GoodyBagCallToAction itemCount={notPreviewableItemCount} /> });
  }

  const {
    currentItem,
    onCloseClick,
    setMediaIndex,
    lightboxIsOpen,
    nextItem,
    prevItem,
    onNextClick,
    onPrevClick,
    setLightboxIsOpen,
    pauseAllVideoPlayers,
    pauseAllAudioPlayers,
    lightboxMediaDecorator,
  } = useFlirtMedia({ mediaItems });

  const onGoodyBagItemClick = (goodyBagItem) => {
    const selectedMediaItemIndex = mediaItems.findIndex(item => item.id === goodyBagItem.id);
    setMediaIndex(selectedMediaItemIndex);
    setLightboxIsOpen(true);
  };

  const pauseAllPlayers = () => {
    pauseAllAudioPlayers();
    pauseAllVideoPlayers();
  };

  const onPreviewClose = () => {
    pauseAllPlayers();
    onCloseClick();
  };

  // Drawer Management
  const drawerAnchor = 'right';

  const [isDetailsDrawerOpen, setIsDetailsDrawerOpen] = useState(!!selected);
  const [isRatingsDrawerOpen, setIsRatingsDrawerOpen] = useState(false);

  const { onGoodyBagButtonClick, returnUrl } = useGoodyBagActions({
    goodyBag,
    viewingSelf,
    availableBalance,
    currentUser,
    advisorLoading,
    advisor,
    returnUrlTemplate,
  });

  const ignoreCloseDrawerEvents = (event) => (
    (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) ||
      event.target.tagName === 'BUTTON'
  );

  const openDetailsDrawer = () => {
    setIsDetailsDrawerOpen(true);
    const encodedLogin = encodeURIComponent(advisor.login);

    // if we're not on a storefront or profile page, don't navigate- just set a search param
    if (document.location.pathname.match(`/${encodedLogin}/(goodies|gb)`)) {
      navigate(`/${encodedLogin}/goodies/${goodyBag.id}`, { replace: false });
    } else {
      searchParams.append('goodyBagId', goodyBag.id);
      setSearchParams(searchParams);
    }
  };

  const closeDetailsDrawer = (event) => {
    if (ignoreCloseDrawerEvents(event)) return;

    onPreviewClose();
    setIsDetailsDrawerOpen(false);
    searchParams.delete('goodyBagId');
    setSearchParams(searchParams);
  };

  const openRatingsDrawer = () => {
    pauseAllPlayers();
    triggerGoodyBagReviewsQuery({ goodyBagId: goodyBag.id });
    setIsRatingsDrawerOpen(true);
  };

  const closeRatingsDrawer = (event) => {
    if (ignoreCloseDrawerEvents(event)) return;

    setIsRatingsDrawerOpen(false);
  };

  useEffect(() => {
    if (isDetailsDrawerOpen) {
      triggerGoodyBagReviewsQuery({ goodyBagId: goodyBag.id });
      triggerGoodyBagItemsQuery({ goodyBagId: goodyBag.id });
    }
  }, [isDetailsDrawerOpen]);

  // If they tried deep linking to this specific goody bag, open the details drawer.
  // But not if they're trying to buy it, because clicking buy in the drawer would
  // close the drawer anyway.
  useEffect(() => {
    if (selectedGoodyBagId && (goodyBag.id === parseInt(selectedGoodyBagId))) {
      openDetailsDrawer();

      if (action === 'buy') {
        onGoodyBagButtonClick();

        // Remove the buy action so that we don't get stuck in a loop of trying
        // to buy the same goody bag.
        searchParams.delete('action');
        setSearchParams(searchParams);
      }
    }
  }, []);

  const renderRating = () => {
    if (!goodyBag.total_ratings === 0) {
      return <Typography data-test-id="rating-container" sx={{ color: '#aaa', fontSize: '0.813em' }}>Not Yet Rated</Typography>;
    }

    return (
      <div data-test-id="rating-container" className="row margin0">
        <ThumbUpAltIcon style={{ color: '#aaa', fontSize: 20, marginRight: '5px' }} />
        <Typography sx={{ color: '#aaa', fontSize: '0.813em' }}>
          <Link href="#" sx={{ color: '#aaa' }} onClick={openRatingsDrawer}>
            {goodyBag.approval}
            %
          </Link>
        </Typography>
      </div>
    );
  };

  const cardTitle = viewingSingleAdvisor ? goodyBag.title : advisor.login;

  return (
    <>
      <StyledCard>
        <Grid container data-test-id="goody-bag-card" spacing={0}>
          <Grid item>
            <Box
              sx={{
                width: '150px',
                height: '180px',
                position: 'absolute',
                overflow: 'hidden',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: '1',
              }}
            >
              <img src={goodyBag.thumbnail_url} alt="Card" style={{ width: '100%', height: '100%', objectFit: 'contain', cursor: 'pointer' }} loading="lazy" onClick={openDetailsDrawer} />
            </Box>
            <BlurBox style={{ backgroundImage: `url(${goodyBag.thumbnail_url})` }} />
          </Grid>
          <RightColumn item>
            {showContext && (
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                {goodyBag.pinned && (
                  <img src="/plus_frontend/assets/svg/ico-pin.svg" title="Pinned" alt="Pinned" className={`icon-pin-post ${pinIconClass}`} />
                )}
                {viewingSelf && (
                  <GoodyBagActionMenu actions={actions} />
                )}
              </Box>
            )}
            <NameTypography variant="h6">
              {
                titleIsLink ? (
                  <a href="#" onClick={openDetailsDrawer}>
                    {cardTitle}
                  </a>
                ) : (
                  <SanitizedHTML html={cardTitle} />
                )
              }
            </NameTypography>
            <DescBox className="description">
              <a href="#" onClick={openDetailsDrawer}>
                <SanitizedHTML html={viewingSingleAdvisor ? goodyBag.description : goodyBag.title} />
              </a>
            </DescBox>
            <RatingContainer>
              {renderRating()}
            </RatingContainer>
            <Grid container alignItems="center" style={{ padding: '0px', marginTop: '10px' }}>
              <Grid item xs={12} sm={12} sx={{ display: 'flex', justifyContent: 'center', height: '34px' }}>
                <AuthenticatedLink
                  role="dialog"
                  onClick={onGoodyBagButtonClick}
                  currentUser={currentUser}
                  className="buy-now-button buy-now goody"
                  returnUrl={returnUrl}
                >
                  <span>Buy Now</span>
                </AuthenticatedLink>
              </Grid>
              <Grid item xs={12} sm={12} sx={{ display: 'flex', justifyContent: 'center', marginTop: '7px' }}>
                <Typography variant="body3" data-test-id="price" sx={{ color: '#999', fontSize: '0.813em' }}>
                  <span style={{ color: '#444', display: 'inline-block' }}>
                    {toCurrency(goodyBag.price)}
                  </span>
                </Typography>
              </Grid>
            </Grid>
          </RightColumn>
        </Grid>
      </StyledCard>
      <GoodyBagDetailsDrawer
        anchor={drawerAnchor}
        currentUser={currentUser}
        goodyBag={goodyBag}
        goodyBagReviews={goodyBagReviews}
        isLoadingReviews={goodyBagReviewsResult?.isLoading}
        goodyBagItems={goodyBagItems}
        previewableItems={mediaItems}
        notPreviewableItemCount={notPreviewableItemCount}
        isOpen={isDetailsDrawerOpen}
        closeDrawer={closeDetailsDrawer}
        openRatingsDrawer={openRatingsDrawer}
        closeRatingsDrawer={closeRatingsDrawer}
        isRatingsDrawerOpen={isRatingsDrawerOpen}
        pauseAllPlayers={pauseAllPlayers}
        advisor={advisor}
        onGoodyBagItemClick={onGoodyBagItemClick}
        onBuyGoodyBagButtonClick={onGoodyBagButtonClick}
      />
      <GoodyBagReviewsList
        goodyBag={goodyBag}
        currentUser={currentUser}
        goodyBagReviews={goodyBagReviews}
        isDrawerOpen={isRatingsDrawerOpen}
        closeDrawer={closeRatingsDrawer}
        openDrawer={openRatingsDrawer}
        isLoadingReviews={goodyBagReviewsResult?.isLoading}
      />
      {lightboxIsOpen && (
        <Lightbox
          reactModalStyle={{ overlay: { zIndex: 9000 } }}
          // we should always use the image thumbnail; video and other fullsize media
          //  are only available if you've purchased the goody bag and are viewing it
          //  in the file manager
          mainSrc={lightboxMediaDecorator(currentItem, 'thumb')}
          nextSrc={lightboxMediaDecorator(nextItem, 'thumb')}
          prevSrc={lightboxMediaDecorator(prevItem, 'thumb')}
          onCloseRequest={onPreviewClose}
          onMovePrevRequest={onPrevClick}
          onMoveNextRequest={onNextClick}
        />
      )}
    </>
  );
};

GoodyBagCard.defaultProps = {
  availableBalance: 0,
  currentUser: {},
  advisor: {},
  viewingSingleAdvisor: true,
  actions: [],
  titleIsLink: false,
};

GoodyBagCard.propTypes = {
  goodyBag: GoodyBagShape.isRequired,
  viewingSelf: PropTypes.bool.isRequired,
  availableBalance: PropTypes.number,
  currentUser: PropTypes.object,
  advisorLoading: PropTypes.bool.isRequired,
  advisor: PropTypes.object,
  selected: PropTypes.bool.isRequired,
  viewingSingleAdvisor: PropTypes.bool,
  actions: PropTypes.array,
  titleIsLink: PropTypes.bool
};

export default GoodyBagCard;
