import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';

import GoodyBagCardContainer from 'app/containers/GoodyBagCardContainer';

const GoodyBags = ({ goodyBags, currentUser, availableBalance }) => {
  if (!goodyBags.length) return null;

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: { xs: '6px', sm: '10px' }, justifyContent: 'center' }}>
      {goodyBags.map(goodyBag => (
        <Box key={`goodyBag_${goodyBag.id}`} sx={{ width: '330px', flex: '0 0 330px' }}>
          <GoodyBagCardContainer
            goodyBag={goodyBag}
            // TODO: feature enhancement, we should support loading a page of results and opening a specific goody bag
            selected={false}
            viewingSelf={currentUser?.id === goodyBag.user_id}
            availableBalance={availableBalance}
            currentUser={currentUser}
            advisorLoading={false}
            advisor={{ login: goodyBag.owner_name }}
            viewingSingleAdvisor={false} // Goody bags render differently if you're only viewing a single advisor's goody bags
          />
        </Box>
      ))}
    </Box>
  );
};

GoodyBags.defaultProps = {
  goodyBags: [],
  currentUser: {},
  availableBalance: 0,
};

GoodyBags.propTypes = {
  goodyBags: PropTypes.array,
  currentUser: PropTypes.object,
  availableBalance: PropTypes.number,
};

export default GoodyBags;
