import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { usePubNub } from 'pubnub-react';
import { Box, Grid, Typography, Rating } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { featureFlag } from 'app/util/featureFlag';

import { toCurrency } from 'app/helpers/currencyHelpers';
import { callButton } from 'app/helpers/listingHelpers';
import StyledCard from 'app/components/shared/StyledCard';
import BlurBox from 'app/components/shared/BlurBox';
import RightColumn from 'app/components/shared/RightColumn';
import NameTypography from 'app/components/shared/NameTypography';
import DescBox from 'app/components/shared/DescBox';
import RatingContainer from 'app/components/shared/RatingContainer';
import SanitizedHTML from 'app/helpers/SanitizedHTML';
import FeaturedListingsContext from 'app/contexts/FeaturedListingsContext';

const truncateText = (text, maxLength) => {
  if (!text) return '';
  if (text.length <= maxLength) return text;

  return `${text.substring(0, maxLength)}...`;
};

const CustomRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#ffb400', // Yellow color for filled stars
  },
  '& .MuiRating-iconEmpty': {
    color: '#999', // Gray outline for empty stars
  },
  '& .MuiRating-icon': {
    fontSize: '1em', // Adjust the font size of the stars if needed
  },
});

const PhoneListingCard = ({
  listing,
  headingSource,
  viewingFavorites,
  onDetailsClick,
  onFeedbackClick,
  indexInPage,
  isQualifiableFeaturedListingContext,
}) => {
  const navigate = useNavigate();
  const pubnub = usePubNub();
  const heading = headingSource === 'category' ? listing.full_category : listing.login;
  const truncatedHeading = truncateText(heading, 20);
  const truncatedTitle = truncateText(listing?.title, 50);
  const callNowEanbled = featureFlag.enabled('CALL_NOW_20426');

  let featuredListingClick;

  try {
    featuredListingClick = useContext(FeaturedListingsContext).onListingClick;
  } catch (e) {
    // no-op
    // not sure if there's a better way to do this. we only need (and have access to)
    // the FeaturedListingsContext when we're displaying this card in certain
    // situations (home page and categories), but we can't conditionally call
    // the useContext hook or we violate the rules of hooks. so for now we're
    // just catching the error. this could probably be better.
  }

  const onListingLinkClick = (event) => {
    event.preventDefault();

    if (isQualifiableFeaturedListingContext) {
      featuredListingClick({
        event,
        indexInPage,
        listingId: listing.id,
        callbackFn: onDetailsClick,
      });
    } else {
      onDetailsClick();
    }
  };

  const onCallButtonClick = (event) => {
    const callUrl = event.currentTarget.getAttribute('href');
    const navToCallListing = () => {
      if (callNowEanbled) {
        navigate(callUrl);
      } else {
        window.location = callUrl;
      }
    };

    if (isQualifiableFeaturedListingContext) {
      featuredListingClick({
        event,
        indexInPage,
        listingId: listing.id,
        callbackFn: navToCallListing,
      });
    } else {
      navToCallListing();
    }
  };

  const channels = [listing?.push_channel];
  // subscribe on load, and unsubscribe on unload
  useEffect(() => {
    pubnub.subscribe({ channels });
    return () => {
      pubnub.unsubscribe({ channels });
    };
  }, []);

  return (
    <StyledCard className="no-border-fav">
      <Grid container data-test-id="listing-card" spacing={0}>
        <Grid item xs={viewingFavorites ? 4 : ''}>
          <Box sx={{ position: 'relative' }}>
            <Box
              sx={{
                width: '150px',
                height: '180px',
                position: 'absolute',
                overflow: 'hidden',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: '1',
              }}
            >
              <img
                src={listing.photo_url}
                alt="Card"
                style={{ width: '100%', height: '100%', objectFit: 'contain', cursor: 'pointer' }}
                onClick={onListingLinkClick}
              />
            </Box>
            <BlurBox style={{ backgroundImage: `url(${listing.photo_url})` }} />
          </Box>
        </Grid>
        <RightColumn item xs={viewingFavorites ? 8 : false}>
          <NameTypography variant="h6">{truncatedHeading}</NameTypography>
          <DescBox className="description">
            <a onClick={onListingLinkClick} role="button" tabIndex={0}>
              <SanitizedHTML html={truncatedTitle} />
            </a>
          </DescBox>
          <RatingContainer data-test-id="rating-container">
            <a
              onClick={onFeedbackClick}
              role="button"
              tabIndex={0}
              style={{ textDecoration: 'none', cursor: 'pointer' }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <CustomRating
                  name="rating"
                  value={listing.rating}
                  precision={0.5}
                  readOnly
                  size="small"
                />
                <Typography
                  sx={{
                    margin: '-1px 0 0 10px',
                    color: '#444',
                    fontSize: '0.813em',
                  }}
                >
                  (
                  {listing.total_ratings}
                  )
                </Typography>
              </Box>
            </a>
          </RatingContainer>
          <Grid
            container
            alignItems="center"
            style={{ padding: '0px', marginTop: '10px' }}
          >
            <Grid
              item
              xs={12}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              {callButton(listing, onCallButtonClick)}
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '7px',
              }}
            >
              <Typography
                variant="body2"
                data-test-id="price-per-minute"
                sx={{ color: '#999', fontSize: '0.813em' }}
              >
                <span style={{ color: '#444', display: 'inline-block' }}>
                  {toCurrency(listing.price_per_minute)}
                </span>
                <span style={{ display: 'inline-block' }}>&nbsp;per min.</span>
              </Typography>
            </Grid>
          </Grid>
        </RightColumn>
      </Grid>
    </StyledCard>
  );
};

PhoneListingCard.defaultProps = {
  headingSource: '',
  viewingFavorites: false,
  indexInPage: null,
  isQualifiableFeaturedListingContext: false,
};

PhoneListingCard.propTypes = {
  indexInPage: PropTypes.number,
  headingSource: PropTypes.string,
  viewingFavorites: PropTypes.bool,
  listing: PropTypes.object.isRequired,
  onDetailsClick: PropTypes.func.isRequired,
  onFeedbackClick: PropTypes.func.isRequired,
  isQualifiableFeaturedListingContext: PropTypes.bool,
};

export default PhoneListingCard;
