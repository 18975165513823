import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';

import NavContext from 'app/contexts/NavContext';
import HeaderSearchContainer from 'app/containers/HeaderSearchContainer';

const HamburgerMenuIcon = styled(MenuIcon)(() => ({
  '&.MuiSvgIcon-root ': {
    color: 'white',
    width: '30px',
    height: '30px',
    cursor: 'pointer',
  },
}));

const Header = () => {
  const { toggleExpandedNav } = useContext(NavContext);
  return (
    <header>
      <div className="container-max">
        <div className="container-header">
          <div className="row">
            <div className="col-sm-12 start-sm col-xs-12 center-xs">
              <div className="container-fluid">
                <div className="row start-sm middle-xs around-xs" style={{position: 'relative'}}>
                  <div className="nav-hamburger">
                    <HamburgerMenuIcon
                      sx={{
                        display: {
                          xs: 'block',
                          md: 'none',
                        },
                      }}
                      tabIndex={0}
                      onClick={toggleExpandedNav}
                    />
                  </div>
                  <div className="nfp-logo-container">
                    <Link
                      title="NiteFlirt"
                      reloadDocument
                      to="/"
                      role="link"
                      tabIndex={0}
                    >
                      <img src="https://niteflirt-assets.s3.amazonaws.com/homepage/niteflirt-logo-mob.png" alt="NiteFlirt" />
                    </Link>
                  </div>
                  <div id="box-menu">
                    <ul>
                      <li className="btn_find">
                        <Link
                          title="Phone Sex"
                          reloadDocument
                          to="/search"
                          role="link"
                          tabIndex={0}
                        >
                          PHONE SEX
                        </Link>
                        <span />
                      </li>
                      <li className="btn_chat">
                        {/* <div className="chat-dot" /> */}
                        <Link
                          title="Chat"
                          reloadDocument
                          to="/chat"
                          role="link"
                          tabIndex={0}
                        >
                          CHAT
                        </Link>
                        <span />
                      </li>
                      <li className="btn_goody_search">
                        <Link
                          title="Goodies"
                          reloadDocument
                          to="/goody/search#recent_additions"
                          role="link"
                          tabIndex={0}
                        >
                          GOODIES
                        </Link>
                        <span />
                      </li>
                      <li className="btn_feeds">
                        <Link
                          title="Feeds"
                          reloadDocument
                          to="/feed/explore"
                          role="link"
                          tabIndex={0}
                        >
                          FEEDS
                        </Link>
                        <span />
                      </li>
                      <li className="btn_account">
                        <Link
                          title="Account"
                          reloadDocument
                          to="/account"
                          role="link"
                          tabIndex={0}
                        >
                          ACCOUNT
                        </Link>
                        <span />
                      </li>
                      <li className="search">
                        <HeaderSearchContainer />
                      </li>
                      <li>
                        <span />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
