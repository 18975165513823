import React, { useEffect, useState } from 'react';
import { Box, Grid, TextField, Typography, InputAdornment, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';

import AppBody from 'app/components/layout/AppBody';
import TemplateShell2 from 'app/components/layout/TemplateShell2';
import GenderSelectChips from 'app/components/GenderSelectChips';
import ChatTabs from 'app/components/chat/ChatTabs';
import ChatBrowseResults from 'app/components/chat/ChatBrowseResults';
import CircularProgress from 'app/components/shared/CircularProgress';
import NumberlessPagination from 'app/components/NumberlessPagination';

import { useChatProfileSearchMutation, useGetAppConfigQuery } from 'app/api/mainApi';
import {
  setCategory,
  setText,
  clearAllButText,
  clearAllButCategory,
  categorySelector,
  filtersSelector,
  setResultCounts,
} from 'app/slices/chatProfileFilterSlice';

const ChatTabsPos = styled(Box)(() => ({
  '&.MuiBox-root': {
    borderTop: '1px solid #e0e0e0',
    borderBottom: '1px solid #e0e0e0',
    marginTop: '16px',
  },
}));

const ChatBrowseContainer = () => {
  const appConfig = useGetAppConfigQuery();
  const currentUser = appConfig?.data?.current_user;
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const category = useSelector(categorySelector);
  const filters = useSelector(filtersSelector);
  const { text } = filters;

  const [
    chatProfileSearch,
    {
      data: chatProfileSearchResults,
      isSuccess: chatProfileSearchResultsSuccess,
      isLoading: chatProfileSearchResultsLoading,
    },
  ] = useChatProfileSearchMutation();

  const triggerChatProfileSearch = () => {
    if (text.length > 0 && text.length < 3) {
      return;
    }

    chatProfileSearch({ ...filters, category, page });
  };

  const handleCategoryChange = (category) => {
    setPage(1);
    dispatch(clearAllButText());
    dispatch(setCategory(category));
  };

  const handleTextChange = (event) => {
    dispatch(setText(event.target.value));
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    if (chatProfileSearchResultsSuccess && chatProfileSearchResults) {
      dispatch(setResultCounts(chatProfileSearchResults.facet));
    }
  }, [chatProfileSearchResultsSuccess]);

  // clear filters on page load except for category
  useEffect(() => {
    dispatch(clearAllButCategory());
  }, []);

  // trigger a search when the filters change
  useEffect(() => {
    const debouncedFunction = debounce(triggerChatProfileSearch, 500);
    debouncedFunction();
    return debouncedFunction.cancel;
  }, [filters, category, page]);

  // save facet/filter count data when it is returned along with results
  useEffect(() => {
    if (chatProfileSearchResultsSuccess && chatProfileSearchResults) {
      dispatch(setResultCounts(chatProfileSearchResults.facet));
    }
  }, [chatProfileSearchResultsSuccess]);

  return (
    <AppBody>
      <TemplateShell2 header="Chat" goBackPath="/">
        <Grid container sx={{ pt: 2, px: 3, mt: 1.5 }} md={6} sm={12} xs={12}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="outlined-basic"
              placeholder="Find Flirts to chat with"
              variant="outlined"
              size="small"
              value={text}
              onChange={handleTextChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: text.length > 0 && (
                  <InputAdornment position="end">
                    <IconButton onClick={() => dispatch(setText(''))}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <GenderSelectChips state={category} setState={handleCategoryChange} />
          </Grid>
        </Grid>
        <ChatTabsPos>
          <Grid container md={6} sm={12} xs={12}>
            <ChatTabs activeTab="browse" currentUser={currentUser} />
          </Grid>
        </ChatTabsPos>
        {chatProfileSearchResults?.chat_profiles && (
          <>
            <ChatBrowseResults results={chatProfileSearchResults.chat_profiles} />
            {(chatProfileSearchResults?.has_next || chatProfileSearchResults?.has_previous) && (
              <NumberlessPagination
                hasNext={chatProfileSearchResults?.has_next}
                page={chatProfileSearchResults?.current_page}
                onChange={handlePageChange}
              />
            )}
          </>
        )}
        {chatProfileSearchResultsLoading && (
          <Box sx={{ textAlign: 'center', mt: 4 }}>
            <CircularProgress />
            <Typography variant="body1" sx={{ mt: 2 }}>
              Loading...
            </Typography>
          </Box>
        )}
        {text && chatProfileSearchResults?.chat_profiles.length < 1 && !chatProfileSearchResultsLoading && (
          <Box sx={{ textAlign: 'center', mt: 2 }}>
            <h2>No results found</h2>
            <p>
              We can&apos;t find any results based on your search.
              <br />
              {' '}
              Please try again.
            </p>
          </Box>
        )}

      </TemplateShell2>
    </AppBody>
  );
};

export default ChatBrowseContainer;
