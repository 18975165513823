import { useNavigate, useLocation } from 'react-router-dom';

// TODO: we'll need to clean up the Feature Flag in this hook... eventually
const useListingDetailsAndFeedback = ({ listingDetailsFlagEnabled }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const onListingDetailsClick = (listing) => {
    if (listingDetailsFlagEnabled) {
      navigate(`${location.pathname}?listing_id=${listing.id}`);
    } else {
      window.location = `/listings/show/${listing.slug}`;
    }
  };

  const onListingFeedbackClick = (listing) => {
    if (listingDetailsFlagEnabled) {
      navigate(`${location.pathname}?feedback_listing_id=${listing.id}`);
    } else {
      window.location = `/listings/show/${listing.slug}`;
    }
  };

  return { onListingDetailsClick, onListingFeedbackClick };
};

export default useListingDetailsAndFeedback;
