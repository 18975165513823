import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Typography } from '@mui/material';

import MenuSelect from 'app/components/shared/MenuSelect';

const sortOptions = {
  0: 'Top Available',
  1: 'Highest Rating',
  2: 'Less Than $3',
  3: 'Less Than $2',
};

const ListingSort = ({
  handleSortChange
}) => {
  return (
        <MenuSelect
          onSelect={handleSortChange}
          menuItems={sortOptions}
          defaultValue={Object.keys(sortOptions)[1]}
        />
  );
};

ListingSort.propTypes = {
  handleSortChange: PropTypes.func.isRequired,
  showText: PropTypes.bool.isRequired,
};

export default ListingSort;
