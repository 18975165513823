import React from 'react';
import { PropTypes } from 'prop-types';
import {
  Box,
  Button,
  Stack,
  Typography,
} from '@mui/material';

import camelizeKeys from 'app/util/camelizeKeys';
import PerkList from 'app/components/tributes/PerkList';
import PackagesIconNF from 'app/components/customSvgs/PackagesIconNF';
import AuthenticatedLink from './shared/AuthenticatedLink';
import { TRIBUTE_ACTIONS } from 'app/constants/tributeActions';

const PackageDetails = ({
  currentFilter,
  currentUser,
  packageData,
  packageTributeAction,
}) => {
  const camelizedPackage = camelizeKeys(packageData.package);
  const { feedPerk, callPerk, volleyPerk } = camelizedPackage;
  const hasPerks = feedPerk || callPerk || volleyPerk;

  return (
    <Box mt={1}>
      <Typography variant="h4" pl={1}>Packages</Typography>
      {currentFilter === 'active' && (
        <Box mt={1} pl={1} pr={1} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', flexDirection: 'row' }}>
          <Box>
            <AuthenticatedLink
              role="link"
              tabIndex={0}
              title="Edit Recurring Tribute"
              currentUser={currentUser}
              onClick={() => packageTributeAction(TRIBUTE_ACTIONS.EDIT_PACKAGE_TRIBUTE, packageData.id, packageData.rate)}
            >
              {packageData.rate}
              {' '}
              per month
            </AuthenticatedLink>
          </Box>
          {packageData.status === 'active' && (
            <Box>
              Renews
              {' '}
              {packageData.renewalDate}
            </Box>
          )}
          {packageData.status === 'pending_cancel' && (
            <Box>
              Expires
              {' '}
              {packageData.renewalDate}
            </Box>
          )}
        </Box>
      )}
      {(currentFilter === 'expired') && (
        <Box mt={1} pl={1} pr={1} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', flexDirection: 'row' }}>
          <Box>
            {packageData.rate}
            {' '}
            per month
          </Box>
          <Box>
            Expired&nbsp;
            {packageData.renewalDate}
          </Box>
        </Box>
      )}

      {hasPerks && (
        <Box style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', flexDirection: 'column' }}>
          <Typography variant="h6" className="name" mt={2} mb={-1} pl={1}>Perks:</Typography>
          <PerkList packageData={camelizedPackage} />
        </Box>
      )}
      {!packageData.renewalDate && (
        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '5px' }}>
          <Button
            variant="contained"
            color="secondary"
            size="medium"
            style={{ maxWidth: '230px' }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <PackagesIconNF style={{ marginRight: '5px' }} />
              <Typography sx={{ fontSize: '1.125em' }}>
                {camelizedPackage.rate}
              </Typography>
              <Typography sx={{ fontWeight: '300', fontSize: '0.938em', pt: 0.4 }}>
                per month
              </Typography>
            </Stack>
          </Button>
        </Box>
      )}
    </Box>
  );
};

PackageDetails.propTypes = {
  currentFilter: PropTypes.string.isRequired,
  currentUser: PropTypes.object.isRequired,
  packageData: PropTypes.object.isRequired,
  packageTributeAction: PropTypes.func.isRequired,
};

export default PackageDetails;
