import React, { useState, useEffect } from 'react';

import { useSearchParams, useNavigate } from 'react-router-dom';

import {
  useGetListingFeedbacksQuery,
  useGetListingQuery,
  useGetAppConfigQuery,
} from 'app/api/mainApi';

import ListingFeedbackDrawer from 'app/components/ListingFeedbackDrawer';

const ListingFeedbackDrawerContainer = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [commentsFirstSort, setCommentsFirstSort] = useState(true);
  const listingId = searchParams.get('listing_id');
  const feedbackListingId = searchParams.get('feedback_listing_id');
  const [feedbackPage, setFeedbackPage] = useState(1);
  const feedbacksPerPage = 20;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { data: appConfig } = useGetAppConfigQuery();

  const sorts = [
    { value: 0, label: 'Most Recent with Comments' },
    { value: 1, label: 'Most Recent' },
  ];

  let pageCount;

  const { data: listing } = useGetListingQuery(
    { id: feedbackListingId },
    { skip: !feedbackListingId },
  );

  const {
    data: listingFeedbackData,
    isSuccess: listingFeedbacksSuccess,
  } = useGetListingFeedbacksQuery({
    id: feedbackListingId,
    commentSort: commentsFirstSort,
    page: feedbackPage,
    perPage: feedbacksPerPage,
  }, {
    skip: !feedbackListingId,
  });

  if (listingFeedbacksSuccess) {
    pageCount = Math.ceil(listingFeedbackData.totalCount / feedbacksPerPage);
  }

  const handleSortChange = (sort) => {
    // 0 is for comments first, 1 is for most recent first
    setCommentsFirstSort(sort.value === 0);
    setFeedbackPage(1);
  };

  const onFeedbackPageChange = (e, value) => {
    setFeedbackPage(value);
  };

  const onDrawerClose = () => {
    if (listingId) {
      navigate(`${window.location.pathname}?listing_id=${listingId}`);
    } else {
      navigate(window.location.pathname);
    }
  };

  useEffect(() => {
    if (listingFeedbacksSuccess) {
      setDrawerOpen(true);
    }
  }, [listingFeedbacksSuccess]);

  useEffect(() => {
    if (!feedbackListingId) {
      setDrawerOpen(false);
    }
  }, [feedbackListingId]);

  if (!listingFeedbacksSuccess) { return null; }

  return (
    <ListingFeedbackDrawer
      open={drawerOpen}
      onClose={() => onDrawerClose(false)}
      listing={listing}
      handleSortChange={handleSortChange}
      sorts={sorts}
      listingFeedbacks={listingFeedbackData.feedbacks}
      page={feedbackPage}
      perPage={feedbacksPerPage}
      pageCount={pageCount}
      onPageChange={onFeedbackPageChange}
      currentUser={appConfig?.currentUser}
    />
  );
};

export default ListingFeedbackDrawerContainer;
