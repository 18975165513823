import React from 'react';
import { PropTypes } from 'prop-types';

import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material';

import BackArrow from 'app/components/shared/BackArrow';
import { featureFlag } from 'app/util/featureFlag';
import CreatePackageTooltip from 'app/components/tooltips/CreatePackageTooltip';

const CreatePackage = ({
  isOnboarding,
  //
  feedAccess,
  onFeedAccessChange,
  showFeedPerkToggle,
  //
  freeVolleys,
  setFreeVolleys,
  //
  freeMinutes,
  setFreeMinutes,
  //
  privatePackage,
  setPrivatePackage,
  //
  atMaxPublicPackages,
  //
  callsLimitByPeriod,
  setCallsLimitByPeriod,
  //
  callsPerPeriodAmount,
  setCallsPerPeriodAmount,
  callsPerPeriodAmountError,
  //
  callsPerSessionAmount,
  setCallsPerSessionAmount,
  //
  rate,
  onRateChange,
  rateError,
  setRateError,
  //
  volleysPerPeriodAmount,
  setVolleysPerPeriodAmount,
  volleysPerPeriodAmountError,
  //
  onSaveClick,
  onCancelClick,
  createPackageIsLoading,
  //
  hasAtLeastOnePerk,
}) => {
  return (
    <>
      <div className="container-hdr-sticky2">
        <div className="row middle-xs start-xs nowrap-xs">
          <div className="col-xs-12">
            <BackArrow />
            {!isOnboarding && (
              <h2>Add Package</h2>
            )}
            {isOnboarding && (
              <h2>Add Perks to this Feed Package:</h2>
            )}
          </div>
        </div>
      </div>

      <Container>
        <Grid container mt={5} rowspacing={2}>
          <Grid item container xs={12} spacing={2} alignItems="center">
            <form>
              <Container>
                <Grid container rowspacing={2}>
                  <Grid item xs={12}>
                    <div className="subhead">Price per month</div>
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <TextField
                      data-test-id="price-per-month"
                      hiddenLabel
                      size="small"
                      type="number"
                      value={rate}
                      error={!!rateError}
                      helperText={rateError}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      onChange={(e) => {
                        setRateError('');
                        onRateChange(e.currentTarget.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} mt={5} mb={1}>
                    <div className="subhead">Add Perks:</div>
                  </Grid>
                  {showFeedPerkToggle && !isOnboarding && (
                    <Grid data-test-id="feed-access-perk" item xs={12}>
                      <FormControlLabel
                        control={<Switch />}
                        label="Feed access"
                        value={feedAccess}
                        onChange={(e) => onFeedAccessChange(e.target.checked)}
                      />
                    </Grid>
                  )}
                  <Grid data-test-id="free-minutes-perk" item xs={12}>
                    <FormControlLabel
                      control={(
                        <Switch
                          onChange={(e) => setFreeMinutes(e.currentTarget.checked)}
                        />
                      )}
                      label="Free minutes per call"
                    />
                  </Grid>
                  {freeMinutes && (
                    <Grid data-test-id="free-minutes-perk-details" item xs={12}>
                      <Grid container alignItems="center" ml={4}>
                        <Grid item xs="auto">
                          <FormControl>
                            <Select
                              data-test-id="free-minutes-per-call-select"
                              value={callsPerSessionAmount}
                              onChange={e => {
                                setCallsPerSessionAmount(e.target.value);
                              }}
                            >
                              {[1, 2, 3, 4, 5].map((i) => (
                                <MenuItem key={i} value={i}>{i}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid
                          data-test-id="free-minutes-per-call-description"
                          item
                          xs="auto"
                          ml={1}
                          sx={{ textAlign: 'start' }}
                        >
                          Free&nbsp;
                          {callsPerSessionAmount > 1 ? 'minutes' : 'minute'}
                          &nbsp;per call
                        </Grid>
                        <Grid
                          data-test-id="limit-free-calls-per-month-checkbox"
                          item
                          xs={12}
                        >
                          <FormControlLabel
                            control={(
                              <Checkbox
                                onChange={(e) => {
                                  setCallsLimitByPeriod(e.currentTarget.checked);
                                }}
                                checked={callsLimitByPeriod}
                              />
                            )}
                            label="Limit number of free calls per month"
                          />
                        </Grid>
                        <Grid
                          sx={{ display: callsLimitByPeriod ? 'block' : 'none' }}
                          data-test-id="limit-free-calls-per-month-input"
                          item
                          xs={12}
                        >
                          <Grid container alignItems="center">
                            <Grid item xs="auto">
                              <TextField
                                hiddenLabel
                                size="small"
                                type="number"
                                inputProps={{ min: 1, max: 30 }}
                                onChange={(e) => setCallsPerPeriodAmount(e.target.value)}
                                value={callsPerPeriodAmount}
                                error={!!callsPerPeriodAmountError}
                              />
                            </Grid>
                            <Grid
                              data-test-id="description"
                              item
                              xs="auto"
                              ml={1}
                              sx={{ textAlign: 'start' }}
                            >
                              free minute calls per month
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item xs="auto" sx={{ color: 'red' }}>
                              {callsPerPeriodAmountError && (
                                <p>Please enter between 1 and 30 calls.</p>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {featureFlag.enabled('CHATPERK_FREE_VOLLEYS_19676') && (
                    <Grid data-test-id="free-chat-volleys-per-month-switch" item xs={12}>
                      <FormControlLabel
                        control={<Switch />}
                        onChange={(e) => setFreeVolleys(e.currentTarget.checked)}
                        label="Free chat volleys per month"
                      />
                    </Grid>
                  )}
                  {freeVolleys && (
                    <Grid data-test-id="free-chat-volleys-per-month-input" item xs={12}>
                      <Grid container alignItems="center" ml={4}>
                        <Grid item xs={12}>
                          <Grid container alignItems="center">
                            <Grid item xs="auto">
                              <TextField
                                hiddenLabel
                                size="small"
                                error={!!volleysPerPeriodAmountError}
                                type="number"
                                inputProps={{ min: 1, max: 60 }}
                                value={volleysPerPeriodAmount}
                                onChange={(e) => setVolleysPerPeriodAmount(e.target.value)}
                              />
                            </Grid>
                            <Grid
                              data-test-id="description"
                              item
                              xs="auto"
                              ml={1}
                              sx={{ textAlign: 'start' }}
                            >
                              free volleys per month
                            </Grid>
                          </Grid>
                          <Grid>
                            <Grid item xs="auto" sx={{ color: 'red' }}>
                              {volleysPerPeriodAmountError && (
                                <p>Please enter between 1 and 60 volleys.</p>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {!isOnboarding && (
                    <Grid data-test-id="make-this-package-private" item xs={12}>
                      <FormControlLabel
                        control={(
                          <Checkbox
                            disabled={atMaxPublicPackages}
                            checked={privatePackage}
                            onChange={(e) => setPrivatePackage(e.currentTarget.checked)}
                          />
                        )}
                        label={(
                          <span>Make this Package private.</span>
                        )}
                      />
                      <CreatePackageTooltip
                        atMaxPublicPackages={atMaxPublicPackages}
                      />
                    </Grid>
                  )}
                  <Grid container item xs={12} spacing={1} alignItems="center" mt={1} justifyContent={{ xs: 'center', sm: 'end' }}>
                    <Grid item xs="auto">
                      <Button
                        variant="outlined"
                        size="medium"
                        sx={{ minWidth: { xs: '100%' } }}
                        onClick={onCancelClick}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item xs="auto">
                      <Button
                        sx={{ minWidth: { xs: '100%' } }}
                        disabled={
                          createPackageIsLoading ||
                          !!rateError ||
                          (!privatePackage && atMaxPublicPackages) ||
                          volleysPerPeriodAmountError ||
                          callsPerPeriodAmountError ||
                          !hasAtLeastOnePerk
                        }
                        variant="contained"
                        onClick={onSaveClick}
                        size="medium"
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </form>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

CreatePackage.propTypes = {
  isOnboarding: PropTypes.bool.isRequired,
  //
  freeMinutes: PropTypes.bool.isRequired,
  setFreeMinutes: PropTypes.func.isRequired,
  //
  feedAccess: PropTypes.bool.isRequired,
  onFeedAccessChange: PropTypes.func.isRequired,
  showFeedPerkToggle: PropTypes.bool.isRequired,
  //
  freeVolleys: PropTypes.bool.isRequired,
  setFreeVolleys: PropTypes.func.isRequired,
  //
  privatePackage: PropTypes.bool.isRequired,
  setPrivatePackage: PropTypes.func.isRequired,
  //
  atMaxPublicPackages: PropTypes.bool.isRequired,
  //
  callsPerPeriodAmount: PropTypes.string.isRequired,
  setCallsPerPeriodAmount: PropTypes.func.isRequired,
  callsPerPeriodAmountError: PropTypes.bool.isRequired,
  //
  rateError: PropTypes.string.isRequired,
  setRateError: PropTypes.func.isRequired,
  onRateChange: PropTypes.func.isRequired,
  rate: PropTypes.string.isRequired,
  //
  volleysPerPeriodAmount: PropTypes.string.isRequired,
  setVolleysPerPeriodAmount: PropTypes.func.isRequired,
  volleysPerPeriodAmountError: PropTypes.bool.isRequired,
  //
  callsLimitByPeriod: PropTypes.bool.isRequired,
  setCallsLimitByPeriod: PropTypes.func.isRequired,
  //
  callsPerSessionAmount: PropTypes.number.isRequired,
  setCallsPerSessionAmount: PropTypes.func.isRequired,
  //
  onCancelClick: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  createPackageIsLoading: PropTypes.bool.isRequired,
  //
  hasAtLeastOnePerk: PropTypes.bool.isRequired,
};

export default CreatePackage;
