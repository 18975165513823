import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import PhoneShape from 'app/shapes/PhoneShape';

import {
  Grid,
  Typography,
} from '@mui/material';

import DialogsContext from 'app/contexts/DialogsContext';
import AddPhoneDialog from 'app/components/modals/AddPhoneDialog';

const AddPhoneLink = ({
  currentUser,
  phones,
}) => {
  const { openDialog } = useContext(DialogsContext);

  const clickEvent = () => {
    openDialog({
      component: AddPhoneDialog,
      props: {
        title: 'Add Phone',
        currentUser,
        phones,
        setShowAddPhoneNumber: () => {},
      },
    });
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        maxWidth: 550,
        margin: 'auto',
      }}
    >
      <Grid
        item
        xs={3}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Typography
          variant="body1"
          fontWeight="bold"
        >
          Number
        </Typography>
      </Grid>
      <Grid
        item
        xs={9}
      >
        <Typography>
          <a href="#" onClick={clickEvent}>
            Add Phone
          </a>
        </Typography>
      </Grid>
    </Grid>
  );
};

AddPhoneLink.defaultProps = {
  phones: [],
};

AddPhoneLink.propTypes = {
  currentUser: PropTypes.object.isRequired,
  phones: PropTypes.arrayOf(PhoneShape),
};

export default AddPhoneLink;
