import React from 'react';
import { PropTypes } from 'prop-types';

import styled from '@mui/material/styles/styled';
import { Pagination, PaginationItem } from '@mui/material';

const StyledPagination = styled(Pagination)(({ theme }) => ({
  borderRight: 'none',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  height: 'auto',
  maxWidth: 'calc(100% - 12px)',
  margin: '0px auto',
  '& :not(:first-of-type,:last-child)': {
    display: 'none',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '2.0em',
  },
  '& .MuiButtonBase-root': {
    '&.MuiPaginationItem-root': {
      height: '44px',
      minHeight: '44px',
    },
  },
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '350px',
  },
}));

const NumberlessPagination = ({ hasNext, page, onChange }) => {
  return (
    <StyledPagination
      siblingCount={0}
      count={hasNext ? page + 1 : page}
      page={page}
      onChange={onChange}
      renderItem={(item) => (
        <PaginationItem
          {...item}
          sx={{ borderRadius: '4px', margin: '0 5px', fontSize: '1.5em' }}
        />
      )}
    />
  );
};

NumberlessPagination.defaultProps = {
  hasNext: false,
  page: 1,
};

NumberlessPagination.propTypes = {
  hasNext: PropTypes.bool,
  page: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

export default NumberlessPagination;
