import React from 'react';
import PropTypes from 'prop-types';

import { featureFlag } from 'app/util/featureFlag';
import PhoneListingCard from 'app/components/PhoneListingCard';
import DeprecatedPhoneListingCard from 'app/components/DeprecatedPhoneListingCard';
import useListingDetailsAndFeedback from 'app/hooks/useListingDetailsAndFeedback';

const PhoneListingContainer = ({
  listing,
  headingSource,
  indexInPage,
  isQualifiableFeaturedListingContext,
}) => {
  const newCardsEnabled = featureFlag.enabled('NEW_CARDS_20908');

  const {
    onListingDetailsClick,
    onListingFeedbackClick,
  } = useListingDetailsAndFeedback({
    listingDetailsFlagEnabled: featureFlag.enabled('LISTING_DETAILS_20955'),
  });

  if (newCardsEnabled) {
    return (
      <PhoneListingCard
        listing={listing}
        headingSource={headingSource}
        onDetailsClick={() => onListingDetailsClick(listing)}
        onFeedbackClick={() => onListingFeedbackClick(listing)}
        indexInPage={indexInPage}
        isQualifiableFeaturedListingContext={isQualifiableFeaturedListingContext}
      />
    );
  }

  return (
    <DeprecatedPhoneListingCard
      listing={listing}
      headingSource={headingSource}
    />
  );
};

PhoneListingContainer.defaultProps = {
  headingSource: '',
  indexInPage: null,
  isQualifiableFeaturedListingContext: false,
};

PhoneListingContainer.propTypes = {
  headingSource: PropTypes.string,
  listing: PropTypes.object.isRequired,
  indexInPage: PropTypes.number,
  isQualifiableFeaturedListingContext: PropTypes.bool,
};

export default PhoneListingContainer;
