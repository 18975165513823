import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Tabs, Tab, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const StyledChatTab = styled(Tab)({
  fontSize: '0.875rem',
  padding: '8px 12px',
  minWidth: '80px',
  maxWidth: '130px',
  textAlign: 'center',
  '&.Mui-selected': { color: 'black' },
  '&.Mui-disabled': { color: 'grey' },
});

const ChatTabs = ({ activeTab, currentUser }) => {
  const activeValue = activeTab === 'chat' ? '1' : '2';

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={activeValue}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
      >
        <StyledChatTab
          label="Chats"
          value="1"
          component={Link}
          to="/chat"
          reloadDocument
          disabled={!currentUser}
          sx={{
            color: currentUser ? '#06c' : 'grey',
          }}
        />
        <StyledChatTab
          label="Browse"
          value="2"
          component={Link}
          to="/chat/browse"
          reloadDocument
          sx={{
            color: '#06c',
          }}
        />
      </Tabs>
    </Box>
  );
};

ChatTabs.defaultProps = {
  currentUser: null,
};

ChatTabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  currentUser: PropTypes.object,
};

export default ChatTabs;
