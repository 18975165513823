import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Rating from '@mui/material/Rating';
import Link from '@mui/material/Link';

import { timestampInTimezone } from 'app/helpers/timestampHelpers';

const ListingFeedbackCard = ({ feedback, currentUser }) => {
  const [showMore, setShowMore] = useState(false);
  const maxChars = 80;

  const leftBy = (createdBy, currentUser) => {
    if (currentUser) { return createdBy; }
    return `${createdBy.charAt(0)}...${createdBy.charAt(createdBy.length - 1)}`;
  };

  const showMoreLinkText = () => {
    if (!feedback.comment || feedback.comment === '') { return ''; }
    if (showMore) { return 'See less'; }
    return 'See more';
  };

  const maybeTruncateComment = (comment, showMore) => {
    if (!comment || comment === '') { return ''; }
    if (showMore) { return comment; }
    if (comment.length <= maxChars) { return comment; }
    return `${comment.substring(0, maxChars)}...`;
  };

  return (
    <Box
      m={1.2}
      p={1.2}
      sx={{ backgroundColor: 'white' }}
    >
      <Grid container>
        <Grid item xs={4}>
          <Rating
            value={feedback.rating}
            precision={0.1}
            readOnly
            sx={{ verticalAlign: 'middle' }}
          />
        </Grid>
        <Grid item xs={3}>
          <Typography>
            {leftBy(feedback.created_by, currentUser)}
          </Typography>
        </Grid>
        <Grid item xs={5} textAlign="right">
          <Typography>
            {timestampInTimezone(
              feedback.created_at,
              currentUser?.canonical_iana_timezone,
              'LLL d yyyy',
            )}
          </Typography>
        </Grid>
        <Grid item xs={12} pt={1.3} sx={{ overflow: 'hidden' }}>
          <Typography>
            {maybeTruncateComment(feedback.comment, showMore)}
          </Typography>
          <Link
            onClick={() => setShowMore(!showMore)}
            role="link"
            tabIndex={0}
          >
            {feedback.comment?.length > maxChars &&
              showMoreLinkText()}
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};

ListingFeedbackCard.defaultProps = {
  currentUser: null,
};

ListingFeedbackCard.propTypes = {
  feedback: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
};

export default ListingFeedbackCard;
