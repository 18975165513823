import React from 'react';
import PropTypes from 'prop-types';
import { useMatch } from 'react-router-dom';

import { Box, Grid, Typography } from '@mui/material';

import StyledCard from 'app/components/shared/StyledCard';
import BlurBox from 'app/components/shared/BlurBox';
import RightColumn from 'app/components/shared/RightColumn';
import NameTypography from 'app/components/shared/NameTypography';
import DescBox from 'app/components/shared/DescBox';
import RatingContainer from 'app/components/shared/RatingContainer';
import SanitizedHTML from 'app/helpers/SanitizedHTML';

const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) return text;
  return `${text.substring(0, maxLength)}...`;
};

const ChatListingCard = ({ advisorLogin, listing }) => {
  const encodedAdvisorLogin = encodeURIComponent(advisorLogin);
  const viewingChatBrowse = useMatch('/chat/browse');
  const listingUrl = `/chat/${listing.slug}`;
  const storefrontUrl = `/${encodedAdvisorLogin}`;
  const truncatedName = truncateText(advisorLogin, 20);
  const truncatedDescription = truncateText(listing.description || '', 50);

  const usernameLink = viewingChatBrowse ? storefrontUrl : listingUrl;

  return (
    <StyledCard>
      <Grid container data-test-id="listing-card" spacing={0}>
        <Grid item>
          <Box sx={{ position: 'relative' }}>
            <Box
              sx={{
                width: '150px',
                height: '180px',
                position: 'absolute',
                overflow: 'hidden',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: '1',
              }}
            >
              <img
                src={listing.image_url}
                alt="Card"
                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
              />
            </Box>
            <BlurBox style={{ backgroundImage: `url(${listing.image_url})` }} />
          </Box>
        </Grid>
        <RightColumn item>
          <NameTypography variant="h6"><a href={usernameLink}>{truncatedName}</a></NameTypography>
          <DescBox className="description">
            <a href={listingUrl}>
              <SanitizedHTML html={truncatedDescription} />
            </a>
          </DescBox>
          <RatingContainer>
            <Typography data-test-id="status" sx={{ color: '#393', fontSize: '0.813em' }}>
              {listing?.advisor.available_for_chat && 'Online'}
            </Typography>
          </RatingContainer>
          <Grid
            container
            alignItems="center"
            style={{ padding: '0px', marginTop: '10px' }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              sx={{ display: 'flex', justifyContent: 'center', height: '34px' }}
            >
              <a href={listingUrl}>
                <img src="/niteflirt/images/btn-chatnow.svg" alt="Chat Now" />
              </a>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '7px',
              }}
            >
              <Typography
                variant="body3"
                data-test-id="price"
                sx={{ color: '#999', fontSize: '0.813em' }}
              >
                <span style={{ color: '#444', display: 'inline-block' }}>
                  $0.99
                </span>
                <span style={{ display: 'inline-block' }}>
                  &nbsp;per volley
                </span>
              </Typography>
            </Grid>
          </Grid>
        </RightColumn>
      </Grid>
    </StyledCard>
  );
};

ChatListingCard.propTypes = {
  advisorLogin: PropTypes.string.isRequired,
  listing: PropTypes.object.isRequired,
};

export default ChatListingCard;
