import React, {useEffect, useState} from 'react';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import  { usePerformForgotPinLoginMutation } from 'app/api/mainApi';
import SanitizedHTML from 'app/helpers/SanitizedHTML';
import AutofillableTextField from 'app/components/shared/AutoFillableTextField';
import useRecaptcha from 'app/hooks/useRecaptcha';

const ForgotPin = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [creditCard, setCreditCard] = useState('');
  const [zip, setZip] = useState('');
  const [error, setError] = useState('');

  const { executeRecaptcha, recaptchaComponent } = useRecaptcha();

  const [performForgotPinLogin, { data: loginData, error: loginError }] = usePerformForgotPinLoginMutation();

  const onChange = (token) => {
      additionalParams['g-recaptcha-response'] = token;
      performForgotPinLogin({
        phoneNumber,
        creditCard,
        zip,
        additionalParams
      });
    };

  const onError = () => setError('reCAPTCHA verification failed. Please reload the page to try again.')

  const queryParameters = new URLSearchParams(window.location.search);
  const additionalParams = ['return_to', 'return_url', 'extra'].reduce((acc, key) => {
    if (queryParameters.get(key)) {
      acc[key] = queryParameters.get(key);
    }
    return acc;
  }, {});

  useEffect(() => {
    if (loginData) window.location.href = loginData.redirectTo;
  }, [loginData]);

  const goToRegister = () => {
    window.location.href = 'signup?return_url=&commit=Register+>';
  };

  // Make sure the user cannot input more than expected
  let formattedPhone = phoneNumber;

  // Mask the number as the user types.
  if (phoneNumber.length > 3) {
    formattedPhone = `(${phoneNumber.substring(0, 3)}) `;

    if (phoneNumber.length < 6) {
      formattedPhone += phoneNumber.substring(3)
    } else {
      formattedPhone += phoneNumber.substring(3, 6);
    }

    if (phoneNumber.length > 6) {
      formattedPhone += ' - ' + phoneNumber.substring(6, 10);
    }
  }

  return (
    <DialogContent>
      <Box mx={{ xs: 0, sm: 12, md: 17 }}>
        <Stack direction="column" spacing={3} justifyContent="center" alignItems="center" mb={3}>
          <img src="/plus_frontend/assets/svg//niteflirt-logo.svg" alt="NiteFlirt" style={{ width: '180px' }} />
          <Typography variant="h3">
            Forgot PIN
          </Typography>
          <Typography variant="body1">
            Enter the details below to sign in and complete your account registration.
          </Typography>
          {(loginError || error) && (
            <Stack mb={3}>
              <Typography variant="body3" style={{ color: '#d32f2f' }} class="login-error">
                <SanitizedHTML html={loginError ? loginError.data.error : error } />
              </Typography>
            </Stack>
          )}
        </Stack>
        <Stack direction="column" spacing={3} mb={3}>
          <AutofillableTextField
            label="Phone Number"
            type="tel"
            value={formattedPhone}
            setValue={(value) => setPhoneNumber(value.replace(/\D/g, ""))}
            autoComplete={'off'}
            name="phone"
          />
          <AutofillableTextField
            label="Credit Card (Last 6 digits)"
            variant="outlined"
            size="small"
            type="password"
            name="last_cc_digits"
            value={creditCard}
            autoComplete={'off'}
            setValue={(value) => setCreditCard(value.replace(/\D/g, ""))}
          />
          <AutofillableTextField
            name="zip"
            label="Billing Zip"
            variant="outlined"
            size="small"
            type="password"
            value={zip}
            autoComplete={'off'}
            setValue={(value) => setZip(value)}
          />
        </Stack>
        <Stack direction="row" justifyContent="center" mb={3}>
          <Button
            onClick={executeRecaptcha}
            variant="contained"
            size="large"
            fullWidth
            disabled={!phoneNumber || !creditCard || !zip}
          >
            Sign In
          </Button>
        </Stack>
        <Stack direction="column" justifyContent="center" alignItems="center" mb={2}>
          <Typography variant="h5">
            New to NiteFlirt?
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="center" mb={3}>
          <Button onClick={goToRegister} variant="outlined" size="medium" fullWidth>
            Register
          </Button>
        </Stack>
        <Stack direction="column" justifyContent="center" alignItems="center">
          <Typography variant="body2">
            <a href="/login">Already have a Member Name?</a>
          </Typography>
        </Stack>
        { recaptchaComponent(onChange, onError) }
      </Box>
    </DialogContent>
  )
}

export default ForgotPin;