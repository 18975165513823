import React from 'react';
import PropTypes from 'prop-types';

const SendTextButton = ({ onClick }) => {
  const className = 'call-button send-text-away';
  return (
    <div>
      <a
        href="#"
        onClick={onClick}
      >
        <div className={className}>
          <span>Send Text</span>
        </div>
      </a>
    </div>
  );
};

SendTextButton.defaultProps = {
};

SendTextButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default SendTextButton;
