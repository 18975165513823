import React, { useContext } from 'react';
import PwaInstallInstructions from './dialogs/PwaInstallInstructions';
import DialogsContext from 'app/contexts/DialogsContext';

// These need to be done outside the component given that the event fires only 1 time
// when the browser detects that the app is installable but React re-renders this component 3 times
// and in addition it worked sometimes and others it never fired the event.
// Having them outside makes a consistent behavior.
const handleInstallPrompt = e => {
  e.preventDefault();
  window.deferredPrompt = e;
};

const hideButton = () => {
  document.getElementById("install-button-container").style.display = 'none';
};

window.addEventListener('beforeinstallprompt', handleInstallPrompt);
window.addEventListener('appinstalled', hideButton);

const PwaInstallButton = () => {
  // Do not show the button in the PWA
  if (window.matchMedia(`(display-mode: standalone)`).matches) {
    return null;
  }

  const { openDialog, closeDialog } = useContext(DialogsContext);
  const supportsInstallPrompt = 'onbeforeinstallprompt' in window;

  const installPwa = () => {
    if (supportsInstallPrompt && window.deferredPrompt) {
      window.deferredPrompt.prompt();
      window.deferredPrompt.userChoice.then(choiceResult => {
        window.deferredPrompt = null; // deferredPrompt can only be used once.
      });
    } else if (navigator.userAgent.match(/Firefox/)) {
        alert("Currently PWAs can't be installed in Firefox. Please choose another browser like Safari, Chrome, or Edge.");
    } else {
      openDialog({
        component: PwaInstallInstructions,
        props: { closeDialog },
      });
    }
  };

  return (
    <div id="install-button-container"
      style={{
        textAlign: 'center',
        padding: '0',
        marginTop: '20px',
        backgroundColor: 'transparent',
        width: '100%',
        cursor: 'pointer'
      }}
      onClick={installPwa}
    >
      <img id="pwa-install-button" src="/plus_frontend/assets/svg/pwa-btn-install.svg" alt="Install"
        style={{maxWidth: '170px'}}
      />
    </div>
  )
}

export default PwaInstallButton;