import React, {useContext, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  clearFilters,
  filtersSelector,
  resultCountsSelector,
  urlSelector,
  setResultCounts,
} from 'app/slices/goodyBagFilterSlice';

import {
  DialogTitle,
  DialogContent,
  Button,
  Box,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import DialogsContext from 'app/contexts/DialogsContext';
import CloseDialogButton from 'app/components/modals/CloseDialogButton';
import Facets from 'app/components/goody_search/Facets';
import AutoCompleteMultiCheckbox from '../form_components/AutoCompleteMultiCheckbox';
import { setFilter, categorySelector, sortSelector } from 'app/slices/goodyBagFilterSlice';
import { useGoodyBagSearchMutation } from 'app/api/assetApi';

const CHECKBOX_FILTERS = Facets.filter((f) => f.type === 'checkbox');
const SINGLE_SELECT_FILTERS = Facets.filter((f) => f.type === 'single_select');

const GoodyBagFilters = () => {
  const filters = useSelector(filtersSelector);
  const resultCounts = useSelector(resultCountsSelector);
  const dispatch = useDispatch();
  const { closeDialog } = useContext(DialogsContext);
  const category = useSelector(categorySelector);
  const sort = useSelector(sortSelector);

  const urlFromQuery = useSelector(urlSelector);

  const [
    goodyBagSearch,
    {
      data: goodyBagSearchResults,
      isSuccess: goodyBagSearchResultsSuccess,
    },
  ] = useGoodyBagSearchMutation();

  useEffect(() => {
    if (goodyBagSearchResultsSuccess && goodyBagSearchResults) {
      dispatch(setResultCounts(goodyBagSearchResults.facet));
      window.history.pushState(null, '', `?${urlFromQuery}`);
    }
  }, [goodyBagSearchResultsSuccess]);

  const dispatchClearFilters = () => {
    dispatch(clearFilters());
    goodyBagSearch({ ...filters, category, sort, page: 1, page_size: 12 });
  };

  return (
    <>
      <DialogTitle>
        Filters
      </DialogTitle>
      <CloseDialogButton closeDialog={closeDialog} />
      <DialogContent>
        { CHECKBOX_FILTERS
          .map((f) => [f, resultCounts[f.id]])
          .map(([f, count]) => {
            const counts = {};
            f.values.forEach((v, index) => { counts[v.label] = count ? count[index] : 0 });

            const values = f.values;
            let value = filters[f.id].map((v) => values.find((vl) => vl.id === v).label);
            return <AutoCompleteMultiCheckbox
              label={f.label}
              options={f.values.map((v) => v.label)}
              filters={filters}
              value={value}
              setValue={(currentValues) => {
                const value = currentValues.map((currentV) => values.find((v) => v.label === currentV).id);
                dispatch(setFilter({filter: f.id, value }));
              }}
              allowTyping={false}
              counts={counts}
              showCount={true}
              key={f.id} />}
          ) }

        {
          SINGLE_SELECT_FILTERS.map((f) => {
            const { id, label, values } = f;
            const filterValue = filters[id];

            const valuesOnlyWithCount = values
              .map((value, index) => [value, resultCounts[id] ? resultCounts[id][index] : 0])
              .filter((v) => v[1] > 0);

            return (
              <Box key={id}>
                <InputLabel id={`${id}_label`} sx={{ mt: 2, mb: 1 }}>{label}</InputLabel>
                <Select
                  label={label}
                  labelId={`${id}_label`}
                  options={values.map((v) => v.label)}
                  value={filterValue}
                  onChange={(e) => dispatch(setFilter({ filter: id, value: e.target.value }))}
                  displayEmpty={true}
                >
                  <MenuItem value={''}>Select Date</MenuItem>
                  {valuesOnlyWithCount.map((item) => {
                      const [value, count] = item;
                      return <MenuItem key={value.id} value={value.id}>
                        {value.label}&nbsp;
                        ({count})
                      </MenuItem>
                    }
                  )}
                </Select>
              </Box>
            )
          })
        }

        <Box display="flex" justifyContent="right" mt={2}>
          <Button color="secondary" onClick={dispatchClearFilters} sx={{ mr: 2 }}>Reset</Button>
          <Button variant="contained" onClick={closeDialog}>Apply</Button>
        </Box>
      </DialogContent>
    </>
  );
};

export default GoodyBagFilters;
