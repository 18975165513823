import React from 'react';
import { PropTypes } from 'prop-types';
import Box from '@mui/material/Box';
import styled from '@mui/material/styles/styled';

const BoxBreadcrumb = styled(Box)(() => ({
  '&.MuiBox-root': {
    backgroundColor: '#500e64',
    padding: '10px 15px',
    color: 'white',
    fontSize: '0.813em',
    a: {
      color: 'white',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
}));

const CategoryBreadcrumbs = ({ breadcrumbs }) => {
  if (!breadcrumbs) {
    return null;
  }

  return (
    <BoxBreadcrumb
      dangerouslySetInnerHTML={{ __html: breadcrumbs }}
    />
  );
};

CategoryBreadcrumbs.defaultProps = {
  breadcrumbs: '',
};

CategoryBreadcrumbs.propTypes = {
  breadcrumbs: PropTypes.string,
};

export default CategoryBreadcrumbs;
