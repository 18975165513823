import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useParams } from 'react-router';

import { featureFlag } from 'app/util/featureFlag';

import { useGetAvailableBalanceQuery } from 'app/api/mainApi';
import { useGetGoodyBagsForAdvisorQuery } from 'app/api/assetApi';
import { toNumber } from 'app/helpers/currencyHelpers';
import GoodyBagCardContainer from 'app/containers/GoodyBagCardContainer';
import RoundedPagination from 'app/components/RoundedPagination';
import NumberlessPagination from 'app/components/NumberlessPagination';

const GoodyBagsContainer = ({
  advisor,
  advisorLoading,
  viewingSelf,
  currentUser,
  reloadKey,
  titleIsLink,
}) => {
  if (advisorLoading) return (<div>Loading...</div>);

  const PER_PAGE = 20;
  const balanceData = currentUser ? useGetAvailableBalanceQuery() : {};
  const rawAvailableBalance = balanceData?.data?.available_balance;
  const availableBalance = toNumber(rawAvailableBalance);
  const { goodyBagId } = useParams();
  const newCardsEnabled = featureFlag.enabled('NEW_CARDS_20908');
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [reloadKey]);

  const { data: goodyBagsData, isLoading: goodyBagsLoading } = useGetGoodyBagsForAdvisorQuery({
    userId: advisor?.id,
    selectedGoodyBag: goodyBagId,
    page,
    perPage: PER_PAGE,
  });

  const goodyBags = goodyBagsData?.goody_bags || [];
  const goodyBagsCount = goodyBagsData?.count || 0;
  const pageCount = Math.ceil(goodyBagsCount / PER_PAGE);

  // Determine if pagination is needed
  const hasNext = page < pageCount;

  const onPageChange = (e, value) => {
    const bodyContents = document.getElementsByClassName('body-content');
    if (bodyContents.length > 0) {
      bodyContents[0].scrollTop = 0;
    }
    setPage(value);
  };

  if (goodyBagsLoading) return (<div>Loading...</div>);
  if (!goodyBags.length) return (<div>No Goodies Found</div>);

  if (newCardsEnabled) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: { xs: '6px', sm: '10px' },
          justifyContent: 'center',
        }}
      >
        {goodyBags.map(goodyBag => (
          <Box
            key={`goodyBag_${goodyBag.id}`}
            sx={{
              width: '330px',
              flex: '0 0 auto',
            }}
          >
            <GoodyBagCardContainer
              goodyBag={goodyBag}
              selected={goodyBag.id === goodyBagId}
              viewingSelf={viewingSelf}
              availableBalance={availableBalance}
              currentUser={currentUser}
              advisorLoading={advisorLoading}
              advisor={advisor}
              titleIsLink={titleIsLink}
            />
          </Box>
        ))}
        {pageCount > 1 && (
          <NumberlessPagination
            page={page}
            hasNext={hasNext}
            onChange={onPageChange}
          />
        )}
      </Box>
    );
  }

  return (
    <div
      className="container-fluid padding-0-8"
      data-test-id="goody-bags-container"
      style={{ maxWidth: '757px', marginLeft: '0px' }}
    >
      {goodyBags.map((row) => (
        <div key={`row_${row[0].id}`} className="row">
          {row.map((goodyBag) => (
            <div key={`goodyBag_${goodyBag.id}`} className="col-sm-6 col-xs-12">
              <GoodyBagCardContainer
                goodyBag={goodyBag}
                selected={goodyBag.id === goodyBagId}
                viewingSelf={viewingSelf}
                availableBalance={availableBalance}
                currentUser={currentUser}
                advisorLoading={advisorLoading}
                advisor={advisor}
                titleIsLink={titleIsLink}
              />
            </div>
          ))}
        </div>
      ))}
      {pageCount > 1 && (
        <RoundedPagination
          page={page}
          count={pageCount}
          onChange={onPageChange}
        />
      )}
    </div>
  );
};

GoodyBagsContainer.defaultProps = {
  advisor: {},
  currentUser: {},
  reloadKey: 0,
  titleIsLink: false,
};

GoodyBagsContainer.propTypes = {
  advisor: PropTypes.object,
  advisorLoading: PropTypes.bool.isRequired,
  viewingSelf: PropTypes.bool.isRequired,
  currentUser: PropTypes.object,
  reloadKey: PropTypes.number,
  titleIsLink: PropTypes.bool,
};

export default GoodyBagsContainer;
